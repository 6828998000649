<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
  >
    <g transform="translate(2.985 8.337)">
      <path
        d="M224.053,184.9a.564.564,0,0,1,.638-.39c.244.013.489,0,.733,0a.54.54,0,0,0,.477-.243c.413-.546.831-1.088,1.246-1.632.072-.094.138-.191.207-.288a.628.628,0,0,1,.442-.231,1.45,1.45,0,0,1,.22-.009c1.051,0,2.1,0,3.155,0a1.146,1.146,0,0,1,.972.46c.437.559.9,1.095,1.347,1.647a.729.729,0,0,0,.629.3,2.63,2.63,0,0,1,1.368.3,2.026,2.026,0,0,1,1.07,1.48.561.561,0,0,0,.022.068V187.5a.5.5,0,0,1-.443.38c-.329.013-.659,0-.987,0-.3-.956-.827-1.433-1.608-1.466a1.672,1.672,0,0,0-1.01.265,1.732,1.732,0,0,0-.779,1.2h-2.869a1.729,1.729,0,0,0-1.523-1.458,1.658,1.658,0,0,0-1.044.229,1.742,1.742,0,0,0-.829,1.235c-.274,0-.536-.011-.8,0a.563.563,0,0,1-.638-.389Zm2.816-.4h2.483v-1.437c-.047,0-.086-.005-.126-.005-.355,0-.709,0-1.063,0a.4.4,0,0,0-.327.159c-.045.052-.083.11-.125.165Zm3.449,0h2.219c-.035-.045-.058-.078-.083-.108-.3-.373-.61-.743-.91-1.119a.555.555,0,0,0-.446-.213c-.183,0-.366,0-.55,0h-.229Z"
        transform="translate(-223.831 -182.111)"
        fill="currentColor"
      />
      <path
        d="M260.767,266.825a1.106,1.106,0,1,1-1.108-1.111A1.106,1.106,0,0,1,260.767,266.825Z"
        transform="translate(-256.309 -260.806)"
        fill="currentColor"
      />
      <path
        d="M367.462,266.825a1.105,1.105,0,1,1-1.108-1.111A1.106,1.106,0,0,1,367.462,266.825Z"
        transform="translate(-356.321 -260.806)"
        fill="currentColor"
      />
      <path
        d="M465.162,205.5a.945.945,0,0,1-1.075.69c-.2-.013-.4,0-.611,0a3.051,3.051,0,0,0-1.308-2.08,2.927,2.927,0,0,0-1.788-.51q-2.11.066-2.873,2.568a.819.819,0,0,1-.826-.766c-.008-.078-.006-.158-.006-.237q0-4.07,0-8.14c0-.7.317-1.016,1.008-1.017,1.149,0,2.3.017,3.445-.008a1.442,1.442,0,0,1,1.267.588c.532.7,1.054,1.4,1.592,2.088a5.516,5.516,0,0,1,1.176,2.171Zm-1.39-4.412a2.881,2.881,0,0,0-.209-.456q-.952-1.356-1.92-2.7a.546.546,0,0,0-.48-.235c-.581.008-1.162,0-1.744,0-.062,0-.124.008-.186.013v3.374Z"
        transform="translate(-441.802 -195.184)"
        fill="currentColor"
      />
      <path
        d="M490.283,347.274a1.959,1.959,0,1,1,1.962-1.952A1.974,1.974,0,0,1,490.283,347.274Z"
        transform="translate(-471.595 -333.89)"
        fill="currentColor"
      />
      <path
        d="M289.95,345.44a1.956,1.956,0,1,1-1.931-1.981A1.955,1.955,0,0,1,289.95,345.44Z"
        transform="translate(-281.968 -333.988)"
        fill="currentColor"
      />
      <path
        d="M233.258,305.966a.733.733,0,0,1-.733.733h-3.439s-.791-2.526-3.06-2.526-2.918,2.526-2.918,2.526h-2.1a.733.733,0,0,1-.733-.733v-2.384a.733.733,0,0,1,.733-.733h11.519a.733.733,0,0,1,.733.733Z"
        transform="translate(-220.273 -295.762)"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
